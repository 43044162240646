<template>
  <!-- @swiperight="pageBack" -->
  <v-touch
    class="container" @swiperight="pageBack"
    :swipe-options="{ direction: 'horizontal' }"
  >
    <div class="page_top" ref="PageTop">
      <div class="page_back" @click="pageBack">
        <img src="../assets/back.png" alt=""  class="page_back_icon" />
        <span class="page_back_name">{{ pageInfo.title }}</span>
      </div>
      <div class="page_service">
        <img
          src="../assets/cus.png"
          alt=""
          class="page_service_cus"
          @click="onService"
        />
        <img
          src="../assets/share.png"
          alt=""
          class="page_service_share"
          @click="onShare"
        />
      </div>
    </div>
    <div class="page_scroll_view">
      <div class="page_game" ref="PageGame">
        <div :class="pageInfo.banner ? 'page_banner' : 'x_default page_banner'">
          <p
            class="page_banner_imageview"
            :style="'height:' + availHeight + 'px'"
          >
            <img :src="pageInfo.banner + '?t=' + Date.now()" ref="loadImg" alt="" class="page_banner_image" />
          </p>
        </div>
        <Stand
          :icon="pageInfo.icon"
          :name="pageInfo.name"
          :type="pageInfo.type"
          :des="pageInfo.des"
          :tip="pageInfo.tip"
        ></Stand>

        <Multiline
          :text="pageInfo"
          :line="line"
          :more="more"
          v-on:clickevent="line = 999;more = false"
        ></Multiline>
        <van-tabs v-model="active" animated swipeable>
          <van-tab title="福利">
            <div class="page_tab">
              <Words
                title="储值回馈"
                :lists="welfare.feedback"
                :line="words1"
                class="page_tab_item"
                v-on:clickevent="words1 = 999"
              ></Words>
              
              <div v-if="fuli" class="page_null">
                暂无福利
              </div>
            </div>
          </van-tab>
          <van-tab title="礼包">
            <div class="page_tab">
              <Gift
                v-for="item in giftLists"
                :key="item.id"
                :item="item"
                class="page_gift"
                v-on:clickevent="getGift"
                v-on:clicklook="getLook"
              ></Gift>
              <div v-if="!giftLists[0]" class="page_null">
                暂无礼包
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="page_start" v-if="isurl">
      <div class="page_start_button" @click="clickStart(pageInfo.url)">
        开始游戏
      </div>
    </div>
    <!-- <transition :name="theFade"> -->
    <div v-show="playGame" class="game_content">
      <iframe src="" frameborder="0" class="game_core" ref="iframe"></iframe>
      <div id="ball" class="ball" ref="ball">
        <img src="../assets/ball.png" alt="" class="game_new_customer" @click="openClose" v-if="defaultOpen">
        <div class="open_close" @click="drawerClose" v-else>
          <div class="open_close_view">
            <img src="../assets/l.png" class="open_close_l" alt="">
          </div>
          <div class="open_tip">
            <img src="../assets/ref.png" class="open_tip_img" @click="clickRef" alt="">
            <img src="../assets/close2.png" class="open_tip_img" @click="clickClose" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- </transition> -->
    
    <!-- 登录 -->
    <van-action-sheet
      v-model="admin.login"
      title="用户登录"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="用户名"
          v-model="form.username"
          placeholder="用户名/手机号"
        />
        <van-field
          class="my_field"
          label="密码"
          :type="ps.s1"
          @click-right-icon="clickIcon('s1')"
          right-icon="closed-eye"
          v-model="form.password"
          placeholder="密码6-12位字母或数字组合"
        />
        <div class="my_saveps">
          <van-checkbox v-model="checked_xieyi" icon-size="16px">
            我已经同意<span style="color:#ff5659" @click="isAgreement()">用户协议</span>
          </van-checkbox>
          <van-checkbox v-model="checked" icon-size="16px">记住密码</van-checkbox>
        </div>
        <div style="display:flex">
          <div class="my_save_btn my_login_btn" @click="clickHandle('login')">
            立即登录
          </div>
          <div class="my_save_btn my_login_btn" v-if="isOnekey" @click="clickOnekey()" >
            一键注册
          </div>
        </div>
        <div class="my_other">
          <span @click="onclick('retrieve')">找回密码</span>
          <span @click="onclick('register')">新用户注册</span>
        </div>
      </div>
    </van-action-sheet>
    <!-- 手机注册 -->
    <van-action-sheet
      v-model="admin.register"
      title="手机号注册"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="手机号"
          v-model="form.phone"
          placeholder="输入手机号"
        />
        <div class="my_code">
          <van-field
            class="my_field"
            label="验证码"
            v-model="form.code"
            placeholder="验证码"
          />
          <div
            class="my_getcode"
            @click="clickHandle('getcode', 'c4')"
            ref="c4"
          >
            获取验证码
          </div>
        </div>
        <div class="my_saveps">
          <van-checkbox v-model="checked_xieyi" icon-size="16px">
            我已经同意<span style="color:#ff5659" @click="isAgreement()">用户协议</span>
          </van-checkbox>
          <van-checkbox v-model="checked" icon-size="16px">记住密码</van-checkbox>
        </div>
        <div
          class="my_save_btn my_login_btn"
          @click="clickHandle('phoneregister')"
        >
          注册并登录
        </div>
        <div class="my_other">
          <span></span>
          <span @click="onclick('newuser')">用户名注册</span>
        </div>
      </div>
    </van-action-sheet>
    <!-- 用户名注册 -->
    <van-action-sheet
      v-model="admin.newuser"
      title="用户名注册"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="用户名"
          v-model="form.username"
          placeholder="用户名6-10位字母或数字"
        />
        <van-field
          class="my_field"
          label="密码"
          :type="ps.s4"
          @click-right-icon="clickIcon('s4')"
          right-icon="closed-eye"
          v-model="form.password"
          placeholder="密码6-12位字母或数字组合"
        />
        <div class="my_saveps">
          <van-checkbox v-model="checked_xieyi" icon-size="16px">
            我已经同意<span style="color:#ff5659" @click="isAgreement()">用户协议</span>
          </van-checkbox>
          <van-checkbox v-model="checked" icon-size="16px">记住密码</van-checkbox>
        </div>
        <div
          class="my_save_btn my_login_btn"
          @click="clickHandle('usernameregister')"
        >
          注册并登录
        </div>
      </div>
    </van-action-sheet>
    <!-- 找回密码 -->
    <van-action-sheet
      v-model="admin.retrieve"
      title="找回密码"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="手机号"
          v-model="form.phone"
          placeholder="输入手机号"
        />
        <div class="my_code">
          <van-field
            class="my_field"
            label="验证码"
            v-model="form.code"
            placeholder="验证码"
          />
          <div
            class="my_getcode"
            @click="clickHandle('getcode', 'c3')"
            ref="c3"
          >
            获取验证码
          </div>
        </div>
        <van-field
          class="my_field"
          label="新密码"
          :type="ps.s2"
          @click-right-icon="clickIcon('s2')"
          right-icon="closed-eye"
          v-model="form.password"
          placeholder="密码6-12位字母或数字组合"
        />
        <van-field
          class="my_field"
          label="确认密码"
          :type="ps.s3"
          @click-right-icon="clickIcon('s3')"
          right-icon="closed-eye"
          v-model="form.confirmpassword"
          placeholder="密码6-12位字母或数字组合"
        />
        <div
          class="my_save_btn my_login_btn"
          @click="clickHandle('findpassword')"
        >
          重置密码
        </div>
      </div>
    </van-action-sheet>

    <!-- 支付 -->
    <van-action-sheet
      v-model="show"
      title="支付金额"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <div class="my_payview">
          <div class="my_v">
            <span class="my_symbol">￥</span>
            <span class="my_pay">{{payvalue}}</span>
          </div>
        </div>
        <div class="my_payview">
          <div class="my_pay_txt">支付方式</div>
          <div class="my_pay_center">
            <van-radio-group v-model="radio">
              <van-cell-group>
                <van-cell title="糖豆支付" :icon="doupay" clickable @click="radio = '3'">
                  <template #right-icon>
                    <van-radio name="3" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
            <div class="card_tip_">
              * 糖豆为APP代币，充值后在当前页面使用糖豆支付即可。
            </div>
          </div>
        </div>
        <div class="page_pay" @click="launchPay">立即支付</div>
      </div>
    </van-action-sheet>
    <van-action-sheet
      v-model="buymenu"
      title="糖豆充值"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <div class="card_cur" v-if="cardTip.state == '0'" style="font-size:14px;">
          <div>
            <span style="color: #e80600;">【{{cardTip.name}}】</span>
            <span>可</span>
            <span style="color: #e80600;">加送{{cardTip.rechageRatio}}%</span>
            <span>糖豆</span>
          </div>
          <div>
            <div class="card_cur_pay" @click="goCurPay">购买</div>
            <!--   -->
          </div>
        </div>
        <div class="card_cur" v-if="cardTip.id && cardTip.state != '0'" style="font-size:14px;">
          <div>
            <span>您已拥有</span>
            <span style="color: #e80600;">【{{cardTip.name}}】</span>
            <span>,本次支付</span>
            <span style="color: #e80600;">加送{{cardTip.rechageRatio}}%</span>
            <span>糖豆</span>
          </div>
        </div>
        <div class="card_top">
          <div class="card_i"
            v-for="(item, index) in cards" :key="item.id" 
            @click="selectPay(index)"
            :style="currentSelect == index ? 'background:linear-gradient(90deg,#ff5555 45%,#fe0000 100%);color:#fff;' : '' "
          >
            <span class="card_count">{{item.txt}}</span>
            <span class="card_des">{{item.des}}{{cardTip.id && cardTip.state != '0' ? item.total + item.total / 100 * 15  :item.total}}糖豆</span>
          </div>
        </div>
        <div class="card_tip">
          * 糖豆为APP代币，充值糖豆后去游戏中用糖豆支付即可。
        </div>
        <div class="card_center">
          <div class="card_select">
            <van-radio-group v-model="radio2">
              <van-cell-group>
                <van-cell title="支付宝" :icon="alipay" clickable @click="radio2 = 'blue'">
                  <template #right-icon>
                    <van-radio name="blue"/>
                  </template>
                </van-cell>
                <van-cell title="微信" :icon="wpay" clickable @click="radio2 = 'green'">
                  <template #right-icon>
                    <van-radio name="green"/>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
          </div>
        </div>
        <div class="page_pay" @click="launchPay2">立即充值</div>
      </div>
    </van-action-sheet>

    <!-- 用户协议 -->
    <van-action-sheet
      v-model="admin.agreement"
      title="用户协议"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <p class="my_txt" v-for="item in agreementList" :key="item">
          {{ item }}
        </p>
      </div>
    </van-action-sheet>

    <!-- 实名 -->
    <Idcard :auth="realauth" :name="realname" :idcard="realidcard" v-on:clickevent="realup"></Idcard>
  </v-touch>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { ksort } from "@/utils/ksort";
import {
  askReport,
  askLogin,
  askGetCode,
  askFindPass,
  askPhoneRegister,
  askUserNameRegister,
  askDetailed,
  askGiftlist,
  askAddhistory,
  askGiftCode,
  askCoupon,
  askCouponIndex,
  askCouponPay,
  askCandyPay,
  askPayWith,
  askLeCoin,
  askAppInit,
  askRealName,
  askBindCard,
  askAgreement
} from "@/api";
import { Dialog, Toast } from "vant";
import { UPDATE_USER } from "@/store";
import Words from "@/components/Words.vue";
import Multiline from "@/components/Multiline.vue";
import Stand from "@/components/Stand.vue";
import Gift from "@/components/Gift.vue";
import Idcard from '@/components/Idcard.vue'
import CloseImage from "@/assets/cl.png";
import CustomerImage from '@/assets/cu.png'
import AliPayImage from '@/assets/alipay.png'
import DouImage from '@/assets/dou.png'
import WechatPayImage from '@/assets/wechatpay.png'
import { Encrypt, Decrypt, ajax2, loadPageVar } from "@/utils/index";
import {
  validate,
  birthDate,
} from 'chinese-idcard-checker';
export default {
  name: "Page",
  components: {
    Words,
    Multiline,
    Stand,
    Gift,
    Idcard
  },
  data() {
    return {
      realname: "",
      realidcard: "",
      realauth: false,
      stopPay: 0,
      isurl: false,
      chosenCoupon: -1,
      payvalue: "",
      payinfo: {},
      wpay: WechatPayImage,
      alipay: AliPayImage,
      doupay: DouImage,
      radio: '3',
      radio2: 'blue',
      show: false,
      cashpage: false,
      nowX: 0,
      nowY: 0,
      posX: 0,
      posY: 0,
      app_id: 0,
      screen: 0,
      theFade: "the-game-out",
      playGame: false,
      more: true,
      close: CloseImage,
      customer: CustomerImage,
      active: 0,
      line: 4,
      words1: 4,
      words2: 6,
      availHeight: 0,
      pageInfo: {
        id: null,
        icon: null,
        title: null,
        name: "占位占位",
        banner: null,
        type: ["占位", "占位"],
        des: "占位占位占位占位占位占位",
        text: "占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位",
        tip: "占位"
      },
      welfare: {
        feedback: [
          "占位占位占位",
          "占位占位占位占位占位",
          "占位占位",
          "占位占位占位占位占位占位占位占位",
          "占位占位占位占位占位"
        ],
      },
      giftLists: [
        {
          id: null,
          name: "占位占位",
          des: "占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位",
          count: "19999",
          num: "0",
        },
        {
          id: null,
          name: "占位占位",
          des: "占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位",
          count: "20000",
          num: "0",
        },
        {
          id: null,
          name: "占位占位",
          des: "占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位",
          count: "20000",
          num: "0",
        },
      ],
      admin: {
        login: false,
        register: false,
        newuser: false,
        retrieve: false,
        agreement: false,
      },
      form: {
        username: "", // 用户名
        password: "", // 旧密码
        confirmpassword: "", // 确认密码
        phone: "", // 手机号
        code: "", // 验证码
      },
      ps: {
        s1: "password",
        s2: "password",
        s3: "password",
        s4: "password",
        s5: "password",
        s6: "password",
        s7: "password",
      },
      fuli: false,
      handlegift: false,
      checked_xieyi: true,
      checked: true,
      sourcePath: null,
      buymenu: false,
      // 新增
      currentSelect: 0,
      cards: [
        {
          num: 500,
          txt: '5元',
          des: '充值5元=',
          total: 5,
        },
        {
          num: 1000,
          txt: '10元',
          des: '充值10元=',
          total: 10
        },
        {
          num: 5000,
          txt: '50元',
          des: '充值50元=',
          total: 50
        },
        {
          num: 10000,
          txt: '100元',
          des: '充值100元=',
          total: 100
        },
        {
          num: 20000,
          txt: '200元',
          des: '充值200元=',
          total: 200
        },
        {
          num: 50000,
          txt: '500元',
          des: '充值500元=',
          total: 500
        },
        {
          num: 100000,
          txt: '1000元',
          des: '充值1000元=',
          total: 1000
        },
        {
          num: 200000,
          txt: '2000元',
          des: '充值2000元=',
          total: 2000
        },
        {
          num: 300000,
          txt: '3000元',
          des: '充值3000元=',
          total: 3000
        }
      ],
      params: 500,
      cardTip: {},
      defaultOpen: true,
      isOnekey: false,
      currentGame: null,
      agreementList: []
    };
  },
  mounted() {
    this.rlen = history.length
    // vertical
    if(judgeBigScreen()) {
      this.$refs.PageTop.className = "page_top pageTopAndroid"
      this.$refs.PageGame.className = "page_game pageGameAndroid"
    }
    this.availHeight = (document.body.offsetWidth * 96) / 100 / 2.25
    store.commit("tabbarOut");
  },
  beforeRouteEnter(to,from,next) {
    next(vm => {
      if(vm.$route.query.isonekey == 1) {
        vm.isOnekey = true
      }
      store.commit("tabbarOut");
      vm.sourcePath = from.path
      vm.rlen = history.length
      vm.availHeight = (document.body.offsetWidth * 96) / 100 / 2.25
      var params = {
        game_id: vm.$route.query.id,
        time: Math.floor(Date.now() / 1000)
      }
      params.sign = ksort(params, "qsdhajasfas123sq")
      
      askDetailed(params).then(res => {
        if(res.code === 1) {
          vm.screen = res.data.screen
          var data = res.data
          var explain = ""
          for (const key in data.explain) {
            if (data.explain.hasOwnProperty(key)) {
              explain += data.explain[key]
            }
          }
          if(explain == "无") {
            vm.more = false
          }
          vm.pageInfo = {
            id: data.id,
            icon: data.icon,
            title: data.name,
            name: data.name,
            banner: data.image,
            type: data.play_name,
            des: data.introduction,
            text: explain,
            url: data.game_url,
            tip: data.type_name
          }
          vm.isurl = true

          var feedback = data.feedback
          vm.welfare = {
            feedback: feedback,
          }
          vm.app_id = data.app_id
          var params = {
            app_id: data.app_id,
            token: vm.CYBoxUser ? vm.CYBoxUser.loginToken : "",
            time: Math.floor(Date.now() / 1000)
          }
          params.sign = ksort(params, "qsdhajasfas123sq")
          askGiftlist(params).then(res => {
            if(res.code === 1) {
              var data = []
              res.data.map(e => {
                data.push({
                  id: e.id,
                  name: e.name,
                  des: e.description,
                  count: e.count == 0 ? 1 : e.count,
                  num: e.num,
                  receive: e.receive
                })
              })
              vm.giftLists = data
            } else {
              vm.giftLists = []
            }
          })

          if(vm.$route.query.source == "played") {
            // vm.clickOnekey()
            vm.clickStart(data.game_url);
          }

        }
      })
    })
  },
  computed: {
    ...mapGetters(["CYBoxUser", "appid", "bundle", "CYBoxIDFA", "appkey", "time"]),
  },
  methods: {
    ...mapActions([UPDATE_USER]),
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index;
    },
    goCurPay() {
      Dialog.confirm({
        title: '是否前往购买',
      }).then(() => {
        // on confirm
        // clearInterval(this.iframetimer)
        this.theFade = "the-game-out";
        this.playGame = false;
        document.body.style.overflow = "auto";
        this.show = false
        this.buymenu = false
        try {
          window.webkit.messageHandlers.CYDirection.postMessage("vertical");
        } catch (error) {}
        try {
          window.CYEventCenter.CYDirection("0");
        } catch (error) {}

        // var timer = setTimeout(() => {
          store.commit("tabbarIn");
          this.$router.push('/card')
          // clearTimeout(timer)
        // }, 500)
      }).catch(() => {
        // on cancel
      });
    },
    launchPay() {
      if(this.stopPay == 0) {
        this.payinfo.code = ""
        switch (this.radio) {
          case '1':
            this.payinfo.orderType = "blue"
            break;
          case '2':
            this.payinfo.orderType = "green"
            break;
          case '3':
            this.payinfo.orderType = ""
            break;
          default:
            break;
        }
        if(this.radio == 3) {
          askCandyPay(this.payinfo).then(res => {
            if(res.state === '1') {
              var timer = setTimeout(() => {
                this.show = false
                clearTimeout(timer)
              }, 500)
            } else {
              Toast(res.message);
              this.buymenu = true
              var lecoin = {
                userid: this.CYBoxUser.userID,
                token: this.CYBoxUser.loginToken,
                time: Math.floor(Date.now() / 1000)
              }
              lecoin.sign = ksort(lecoin, 'qsdhajasfas123sq')
              askLeCoin(lecoin).then(res => {
                var msg = res.message
                if(msg) {
                  var cardTip = {}
                  msg.cardInfo.map(e => {
                    if(e.type == '2' && e.id != '5') {
                      cardTip = e
                    }
                  })
                  this.cardTip = cardTip
                }
              })
            }
          })
        } else {
          this.payinfo.sign = ksort(this.payinfo, this.appkey2)
          askCouponPay(this.payinfo).then(res => {
            if(res.state === "1") {
              this.show = false
              try {
                window.CYEventCenter.CYPay(res.message.url);
              } catch (error) {}
              try {
                window.webkit.messageHandlers.CYPay.postMessage(res.message.url);
              } catch (error) {}
            }
          })
        }
        this.stopPay = 1
        var tmer = setTimeout(() => {
          this.stopPay = 0
          clearTimeout(tmer)
        }, 2000)
      } else {
        Toast('请勿过快点击')
      }
    },
    onShare() {
      try {
        window.CYEventCenter.CYShare("https://www.2a3a.com/");
      } catch (error) {}
      try {
        window.webkit.messageHandlers.CYShare.postMessage("https://www.2a3a.com/");
      } catch (error) {}
    },
    getGift(e) {
      if(this.CYBoxUser) {
        this.handlegift = false
        askGiftCode({
          token: this.CYBoxUser.loginToken,
          giftTypeId: e.id,
          currentDeviceIdfa : "",
          deviceId: this.CYBoxIDFA
        }).then(res => {
          if(res.state === "1") {
            var oInput = document.createElement('input');
            oInput.value = res.message;
            document.body.appendChild(oInput);
            oInput.select();
            document.execCommand("Copy");
            oInput.style.display = 'none'
            document.body.removeChild(oInput)
            e.receive = res.message
            Dialog.alert({
              title: res.message,
              message: "兑换码已自动复制, 游戏中直接粘贴即可",
              theme: "round-button",
            }).then(() => {});
          }
        })
      } else {
        this.handlegift = true
        this.admin.login = true;
      }
    },
    getrestGift() {
      var params = {
        app_id: this.app_id,
        token: this.CYBoxUser ? this.CYBoxUser.loginToken : "",
        time: Math.floor(Date.now() / 1000)
      }
      params.sign = ksort(params, "qsdhajasfas123sq")
      askGiftlist(params).then(res => {
        if(res.code === 1) {
          var data = []
          res.data.map(e => {
            data.push({
              id: e.id,
              name: e.name,
              des: e.description,
              count: e.count == 0 ? 1 : e.count,
              num: e.num,
              receive: e.receive
            })
          })
          this.giftLists = data
        } else {
          this.giftLists = []
        }
      })
    },
    getLook(e) {
      var oInput = document.createElement('input');
      oInput.value = e.receive;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.style.display = 'none'
      document.body.removeChild(oInput)
      Dialog.alert({
        title: e.receive,
        message: "兑换码已自动复制, 游戏中直接粘贴即可",
        theme: "round-button",
      }).then(() => {});
    },
    onService() {
      store.commit("incrementOut");
      store.commit("tabbarIn");
      this.$router.push("/customer");
    },
    pageBack() {
      if(this.sourcePath != "/played") {
        store.commit("tabbarIn");
      }
      store.commit("incrementOut");
      this.$router.go(this.rlen - history.length - 1);
      window.removeEventListener('message', this.onMessage);
      // 重置
      Object.assign(this.$data, this.$options.data())
    },
    clickStart(url) {
      // window.location.href = url
      if (this.CYBoxUser) {
        var realparam = {
          appid: this.appid,
          token: this.CYBoxUser.loginToken,
          currentDeviceIdfa: "",
          deviceId: this.CYBoxUser.deviceId
        }
        askRealName(realparam).then(realres => {
          if(realres.state == 1) {
            if(realres.message.card == 0) {
              // 实名
              this.realauth = true
            } else {
              this.theFade = "the-game-in";
              this.playGame = true;
              document.body.style.overflow = "hidden";
              try {
                if(this.screen == 1) {
                  window.webkit.messageHandlers.CYDirection.postMessage("horizontal");
                } else {
                  window.webkit.messageHandlers.CYDirection.postMessage("vertical");
                }
              } catch (error) {}

              var timer = setTimeout(() => {
                var ball = this.$refs.ball
                var query = {
                  addClass: function (node, classname) {
                    if (node.classList) {
                      node.classList.add(classname);
                      return;
                    }
                    if (node.className.indexOf(classname) == -1) node.className += ' ' + classname;
                  },
                  removeClass: function (node, classname) {
                    if (node.classList) {
                      node.classList.remove(classname);
                      return;
                    }
                    var reg = eval("/" + classname + "/ig");
                    node.className = node.className.replace(reg, '');
                  }
                }
                function Inertia(ele, options) {
                  var defaults = {
                    edge: true,
                    startLeft: 0,
                    startTop: 0
                  };
                  var params = {};
                  options = options || {};
                  for (var key in defaults) {
                    if (typeof options[key] !== 'undefined') {
                      params[key] = options[key];
                    } else {
                      params[key] = defaults[key];
                    }
                  }
                  var data = {
                    distanceX: 0,
                    distanceY: 0
                  };
                  var win = window;
                  var winWidth = win.innerWidth;
                  var winHeight = win.innerHeight;
                  if (!ele) {
                    return;
                  }
                  var fnTranslate = function (x, y) {
                    x = Math.round(1000 * x) / 1000;
                    y = Math.round(1000 * y) / 1000;
                    ele.style.webkitTransform = 'translate(' + [x + 'px', y + 'px'].join(',') + ')';
                    ele.style.transform = 'translate3d(' + [x + 'px', y + 'px', 0].join(',') + ')';
                  };
                  query.addClass(ele, 'ball_right');
                  ele.right = ele.offsetWidth / 2 + 'px';
                  var initBound = ele.getBoundingClientRect();
                  if (initBound.left < -0.5 * initBound.width ||
                    initBound.top < -0.5 * initBound.height ||
                    initBound.right > winWidth + 0.5 * initBound.width ||
                    initBound.bottom > winHeight + 0.5 * initBound.height
                  ) {
                    ele.distanceX = 0;
                    ele.distanceY = 0;
                    fnTranslate(0, 0);
                  }
                  ele.addEventListener('touchstart', function (event) {
                    var events = event.touches[0] || event;
                    data.posX = events.pageX;
                    data.posY = events.pageY;
                    data.touching = true;
                    if (ele.distanceX) {
                      data.distanceX = ele.distanceX;
                    }
                    if (ele.distanceY) {
                      data.distanceY = ele.distanceY;
                    }
                    // 元素的位置数据
                    data.bound = ele.getBoundingClientRect();
                    params.startLeft = data.bound.left;
                    params.startTop = data.bound.top;
                    data.timerready = true;
                  });
                  var easeOutBounce = function (t, b, c, d) {
                    if ((t /= d) < (1 / 2.75)) {
                      return c * (7.5625 * t * t) + b;
                    } else if (t < (2 / 2.75)) {
                      return c * (7.5625 * (t -= (1.5 / 2.75)) * t + 0.75) + b;
                    } else if (t < (2.5 / 2.75)) {
                      return c * (7.5625 * (t -= (2.25 / 2.75)) * t + 0.9375) + b;
                    } else {
                      return c * (7.5625 * (t -= (2.625 / 2.75)) * t + 0.984375) + b;
                    }
                  };
                  document.addEventListener('touchmove', function (event) {
                    if (data.touching !== true) {
                      return;
                    }
                    if (data.timerready == true) {
                      data.timerstart = +new Date();
                      data.timerready = false;
                    }
                    event.preventDefault();
                    var events = event.touches[0] || event;
                    data.nowX = events.pageX;
                    data.nowY = events.pageY;
                    var distanceX = data.nowX - data.posX,
                      distanceY = data.nowY - data.posY;
            
                    // 此时元素的位置
                    var absLeft = data.bound.left + distanceX,
                      absTop = data.bound.top + distanceY,
                      absRight = absLeft + data.bound.width,
                      absBottom = absTop + data.bound.height;
                    // 边缘检测
                    if (absLeft < 0) {
                      distanceX = distanceX - absLeft;
                    }
                    if (absTop < 0) {
                      distanceY = distanceY - absTop;
                    }
                    if (absRight > winWidth) {
                      distanceX = distanceX - (absRight - winWidth);
                    }
                    if (absBottom > winHeight) {
                      distanceY = distanceY - (absBottom - winHeight);
                    }
                    // 元素位置跟随
                    var x = data.distanceX + distanceX,
                      y = data.distanceY + distanceY;
                    fnTranslate(x, y);
                    // 缓存移动位置
                    ele.distanceX = x;
                    ele.distanceY = y;
                  });
                  document.addEventListener('touchend', function (event) {
                    var targetData = ele.getBoundingClientRect(),
                      topdatames = targetData.top - params.startTop,
                      leftdatames = params.startLeft - targetData.left;
                    if (data.touching === false) {
                      return;
                    }
                    data.touching = false;
                    // 计算速度
                    data.timerend = +new Date();
                    if (!data.nowX || !data.nowY) {
                      return;
                    }
                    // 移动的水平和垂直距离
                    var distanceX = data.nowX - data.posX,
                      distanceY = data.nowY - data.posY;
                    if (Math.abs(distanceX) < 5 && Math.abs(distanceY) < 5) {
                      return;
                    }
                    // 距离和时间
                    var distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY),
                      time = data.timerend - data.timerstart;
                    // 速度，每一个自然刷新此时移动的距离
                    var speed = distance / time * 16.666;
                    // 设置衰减速率
                    // 数值越小，衰减越快
                    var rate = Math.min(10, speed);
                    data.inertiaing = true;
                    // 反弹的参数
                    var reverseX = 1,
                      reverseY = 1;
                    // 速度计算法
                    var step = function () {
                      if (data.touching == true) {
                        data.inertiaing = false;
                        return;
                      }
                      speed = speed - speed / rate;
                      // 根据运动角度，分配给x, y方向
                      var moveX = reverseX * speed * distanceX / distance,
                        moveY = reverseY * speed * distanceY / distance;
                      // 此时元素的各个数值
                      var bound = ele.getBoundingClientRect();
                      if (moveX < 0 && bound.left + moveX < 0) {
                        moveX = 0 - bound.left;
                        // 碰触边缘方向反转
                        reverseX = reverseX * -1;
                      } else if (moveX > 0 && bound.right + moveX > winWidth) {
                        moveX = winWidth - bound.right;
                        reverseX = reverseX * -1;
                      }
                      if (moveY < 0 && bound.top + moveY < 0) {
                        moveY = -1 * bound.top;
                        reverseY = -1 * reverseY;
                      } else if (moveY > 0 && bound.bottom + moveY > winHeight) {
                        moveY = winHeight - bound.bottom;
                        reverseY = -1 * reverseY;
                      }
                      var x = ele.distanceX + moveX,
                        y = ele.distanceY + moveY;
                      // 位置变化
                      fnTranslate(x, y);
                      ele.distanceX = x;
                      ele.distanceY = y;
                      if (speed < 0.1) {
                        speed = 0;
                        if (params.edge == false) {
                          data.inertiaing = false;
                          // if (win.localStorage) {
                          //   localStorage['Inertia_' + ele.id] = [x, y].join();
                          // }
                        } else {
                          // 边缘吸附
                          edge();
                        }
                      } else {
                        requestAnimationFrame(step);
                      }
                    };
                    var turn = '';
                    var edge = function () {
                      // 时间
                      var start = 0,
                        during = 25;
                      // 初始值和变化量
                      var init = ele.distanceX,
                        y = ele.distanceY,
                        change = 0;
                      // 判断元素现在在哪个半区
                      var bound = ele.getBoundingClientRect();
                        if (bound.left + bound.width / 2 < winWidth / 2) {
                          turn = 'left';
                          change = winWidth - bound.left;
                        } else {
                          turn = 'right';
                          change = winWidth - bound.right;
                        }
                      var ballwidth = ele.offsetWidth;
                      var run = function () {
                        if (data.touching == true) {
                          data.inertiaing = false;
                          return;
                        }
                        start++;
                        var x = easeOutBounce(start, init, change, during);
                        // if (isWeiXin()) {
                          query.removeClass(ele, 'ball_left');
                          query.addClass(ele, 'ball_right');
                          if (turn == 'left') {
                            fnTranslate(x - ballwidth / 1.5, y);
                          }
                          if (turn == 'right') {
                            fnTranslate(x + ballwidth / 3, y);
                          }
                        if (start < during) {
                          requestAnimationFrame(run);
                        } else {
                          ele.distanceX = x;
                          ele.distanceY = y;
                          data.inertiaing = false;
                        }
                        var onchange = x - init;
                      };
                      run();
                    };
                    step();
                  });

                }
                function pageLading() {
                  var ballFun = function () {
                    var h5_ball = ball;
                    h5_ball.style.display = 'block';
                    let bodyEl = document.body
                    let topS = 0
                    function stopBodyScroll(isFixed) {
                      if (isFixed) {
                        topS = window.scrollY
                        bodyEl.style.position = 'static'
                        bodyEl.style.top = -topS + 'px'
                      } else {
                        bodyEl.style.position = ''
                        bodyEl.style.top = ''
                        window.scrollTo(0, topS) // 回到原先的top
                      }
                    }
                    function clickEvent(event) {
                      var target = event.target;
                      stopBodyScroll("fixed")
                    }
                    ball.addEventListener('click', clickEvent, false);
                    Inertia(ball)
                  }()
                }
                pageLading();
                clearTimeout(timer)
              }, 500)

              if(!this.currentGame) {
                this.$refs.iframe.src = url + "?t=" + Date.now();
              }
              this.currentGame = url

              if (this.screen == 1) {
                if(judgeBigScreen()) {
                  this.$refs.iframe.className = "game_core gameCoreHAndroid";
                } else {
                  this.$refs.iframe.className = "game_core game_coreH";
                }
                try {
                  window.CYEventCenter.CYDirection("1");
                } catch (error) {}
              } else {
                if(judgeBigScreen()) {
                  this.$refs.iframe.className = "game_core gameCoreSAndroid";
                } else {
                  this.$refs.iframe.className = "game_core game_coreS";
                }
              }
              let self = this
              this.$refs.iframe.onload = () => {
                this.onMessage = this.onMessage.bind(this)
                window.addEventListener('message', this.onMessage)
                askAppInit().then(res => {
                  if(res) {
                    if(res.indexOf(this.app_id) > -1) {
                      let message = {}
                      message.id = "CY_login";
                      self.$refs.iframe.contentWindow.postMessage(message, "*");
                      self.$refs.iframe.contentWindow.close(); //避免frame内存泄漏
                    }
                  }
                })
                // 上报
                var report = {
                  appid: this.appid,
                  eventType: 0,
                  currentAppBuild: this.bundle,
                  currentDeviceIdfa: "",
                  deviceId: this.CYBoxIDFA,
                };
                report.sign = ksort(report, this.appkey);
                askReport(report).then((res) => {});
              };
              var par = {
                token: this.CYBoxUser.loginToken,
                game_id: this.$route.query.id,
                time: Math.floor(Date.now() / 1000)
              }
              par.sign = ksort(par, "qsdhajasfas123sq")
              askAddhistory(par).then(res => {
              })
            }
          }
        })
      } else {
        if(this.$route.query.source == 'played' && this.$route.query.auto == 'true') {
          this.clickOnekey()
        } else {
          this.admin.login = true;
        }
      }
    },
    onMessage(e) {
      let self = this
      let message = self.CYBoxUser;
      delete message.password
      let result = e.data
      if(result.id === "CY_login") {
        message.id = "CY_login";
        message.resultType = "login";
        self.$refs.iframe.contentWindow.postMessage(message, "*")
        self.$refs.iframe.contentWindow.close(); //避免frame内存泄漏
      }
      if(result.id === "CY_pay") {
        if(result.money && result.appid != "60a70363e61dc426") {
          var params = {
            token: self.CYBoxUser.loginToken,
            appid: result.appid,
            productId: 'm_' + result.money,
            CPsendInfo: result.info,
            bundle: self.bundle,
            time: Math.floor(Date.now() / 1000)
          }
          params.sign = ksort(params, result.appkey)
        } else {
          var params = {
            token: self.CYBoxUser.loginToken,
            appid: result.appid,
            productId: result.productId,
            CPsendInfo: result.info,
            bundle: self.bundle,
            time: Math.floor(Date.now() / 1000)
          }
          params.sign = ksort(params, result.appkey)
        }
        askCouponIndex(params).then(res => {
          if(res.state === "1") {
            self.payvalue = res.message.productAtm
            self.appkey2 = result.appkey
            self.payinfo = {
              token: self.CYBoxUser.loginToken,
              appid: result.appid,
              productId: params.productId,
              CPsendInfo: result.info,
              bundle: self.bundle,
              orderType: "blue",
              time: Math.floor(Date.now() / 1000)
            }
            self.show = true
          } else {
            Dialog.alert({
              message: '支付异常, 请联系客服',
              theme: "round-button",
            }).then(() => {});
          }
        })
      }
    },
    openClose() {
      this.defaultOpen = false
    },
    drawerClose() {
      this.defaultOpen = true
    },
    clickClose() {
      Dialog.confirm({
        title: '是否退出游戏',
      }).then(() => {
        // on confirm
        // clearInterval(this.iframetimer)
        this.theFade = "the-game-out";
        this.playGame = false;
        document.body.style.overflow = "auto";
        try {
          window.webkit.messageHandlers.CYDirection.postMessage("vertical");
        } catch (error) {}
        try {
          window.CYEventCenter.CYDirection("0");
        } catch (error) {}
      }).catch(() => {
        // on cancel
      });
    },
    clickRef() {
      this.currentGame = null
      this.clickStart(this.pageInfo.url)
    },
    realup(vname, vidcard) {
      var params = {
        token: this.CYBoxUser.loginToken,
        name: vname,
        number: vidcard,
        currentDeviceIdfa: "",
        deviceId: this.CYBoxIDFA,
      };
      params.sign = ksort(params, this.appkey);
      if(validate(vidcard)) {
        var ctime = Math.floor(Date.now() / 1000)
        var adulttime = (birthDate(vidcard) - 0) / 1000 + 31536000 * 18
        if(ctime > adulttime) {
          if(vname.trim().length < 2) {
            Toast("请输入姓名");
          } else {
            askBindCard(params).then((res) => {
              if (res.state === "1") {
                Toast.success("实名认证成功");
                this.realauth = false
                this.clickStart(this.pageInfo.url)
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          }
        } else {
          Dialog.alert({
            message: '检测到您的年龄为未成年, 禁止登录游戏',
            theme: "round-button",
          }).then(() => {});
        }
      } else {
        Toast("请输入正确的身份证号");
      }
    },
    clickHandle(handle, transmit) {
      switch (handle) {
        case "login":
          if(this.checked_xieyi) {
            var params = {
              appid: this.appid,
              username: this.form.username,
              password: this.form.password,
              time: Math.floor(Date.now() / 1000),
              eventType: 1,
              currentAppBuild: this.bundle,
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            var isKefu = localStorage.getItem('isKefu')
            if(isKefu) params.isKefu = localStorage.getItem('isKefu')
            params.sign = ksort(params, this.appkey);
            askLogin(params).then((res) => {
              if (res.state === "1") {
                //  ----------------------------------
                var temp = res.message;
                temp.password = Encrypt(this.form.password);
                this.UPDATE_USER(temp);
                store.commit("loginIn");
                if(this.checked) {
                  store.commit("CYBoxLists", temp)
                }
                this.admin.login = false;
                if(this.handlegift) {
                  this.getrestGift()
                } else {
                  this.clickStart(this.pageInfo.url);
                }
                this.form = this.$options.data().form;

              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "需要同意用户协议",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        case "getcode":
          var params = {
            appid: this.appid,
            phonenum: this.form.phone,
            time: Math.floor(Date.now() / 1000),
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          params.sign = ksort(params, this.appkey);
          askGetCode(params).then((res) => {
            if (res.state === "1") {
              var i = Number(res.message);
              Toast("验证码已发送, 请查收");
              let timer = setInterval(() => {
                if (i == 0) {
                  this.$refs[transmit].style.cssText +=
                    "background:linear-gradient(90deg, #ff5659 35%, #ff0103 100%);pointer-events: auto;";
                  this.$refs[transmit].innerHTML = "获取验证码";
                  clearInterval(timer);
                } else {
                  this.$refs[transmit].style.cssText +=
                    "background:#666;pointer-events: none;";
                  this.$refs[transmit].innerHTML = i;
                }
                i--;
              }, 1000);
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        case "findpassword":
          if (this.form.password === this.form.confirmpassword) {
            var params = {
              appid: this.appid,
              phonenum: this.form.phone,
              code: this.form.code,
              password: this.form.password,
              time: Math.floor(Date.now() / 1000),
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            params.sign = ksort(params, this.appkey);
            askFindPass(params).then((res) => {
              if (res.state === "1") {
                Toast.success("密码已重置");
                this.admin.retrieve = false;
                this.form = this.$options.data().form;
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "两次密码输入不一致",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        case "phoneregister":
          if(this.checked_xieyi) {
            var params = {
              appid: this.appid,
              phonenum: this.form.phone,
              code: this.form.code,
              time: Math.floor(Date.now() / 1000),
              eventType: 2,
              currentAppBuild: this.bundle,
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            var isKefu = localStorage.getItem('isKefu')
            if(isKefu) params.isKefu = localStorage.getItem('isKefu')
            params.sign = ksort(params, this.appkey);
            askPhoneRegister(params).then((res) => {
              if (res.state === "1") {
                var temp = res.message
                temp.password = Encrypt(res.message.password)
                this.UPDATE_USER(temp);
                store.commit("loginIn");
                if(this.checked) {
                  store.commit("CYBoxLists", temp)
                }
                this.admin.register = false;
                this.admin.login = false;
                Toast("登录成功");
                if(this.handlegift) {
                  this.getrestGift()
                } else {
                  this.clickStart(this.pageInfo.url);
                }
                Dialog.alert({
                  title: '您的初始密码, 请妥善保管',
                  message: Decrypt(res.message.password),
                  theme: "round-button",
                }).then(() => {});
                this.form = this.$options.data().form;
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "需要同意用户协议",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        case "usernameregister":
          if(this.checked_xieyi) {
            var params = {
              appid: this.appid,
              username: this.form.username,
              password: this.form.password,
              time: Math.floor(Date.now() / 1000),
              eventType: 2,
              currentAppBuild: this.bundle,
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            var isKefu = localStorage.getItem('isKefu')
            if(isKefu) params.isKefu = localStorage.getItem('isKefu')
            params.sign = ksort(params, this.appkey);
            askUserNameRegister(params).then((res) => {
              if (res.state === "1") {
                var temp = res.message;
                temp.password = Encrypt(this.form.password);
                this.UPDATE_USER(temp);
                store.commit("loginIn");
                if(this.checked) {
                  store.commit("CYBoxLists", temp)
                }
                this.admin.register = false;
                this.admin.login = false;
                this.admin.newuser = false;
                Toast("登录成功");
                if(this.handlegift) {
                  this.getrestGift()
                } else {
                  this.clickStart(this.pageInfo.url);
                }
                this.form = this.$options.data().form;
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "需要同意用户协议",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        default:
          break;
      }
    },
    clickOnekey() {
      var params = {
        appid: this.appid,
        eventType: 2,
        currentAppBuild: loadPageVar("bundle"),
        currentDeviceIdfa: "",
        deviceId: this.CYBoxIDFA
      }
      params.sign = ksort(params, this.appkey)
      ajax2({
        method: "POST",
        url: '/Login/getName',
        data: params,
        success: res => {
          if (res.state === "1") {
            var temp = res.message;
            this.UPDATE_USER(temp);
            store.commit("loginIn");
            if(this.checked) {
              store.commit("CYBoxLists", temp)
            }
            this.admin.login = false;
            if(this.handlegift) {
              this.getrestGift()
            } else {
              this.clickStart(this.pageInfo.url);
            }
            this.form = this.$options.data().form;
          } else {
            Dialog.alert({
              message: res.message,
              theme: "round-button",
            }).then(() => {});
          }
        }
      })
    },
    onclick(id) {
      this.admin[id] = true;
    },
    clickIcon(e) {
      this.ps[e] = "text";
    },
    touchStart () {
      var events = event.touches[0] || event;
      this.posX = events.pageX;
      this.posY = events.pageY;
    },
    touchMove (event) {
      var events = event.touches[0] || event;
      this.nowX = events.pageX;
      this.nowY = events.pageY;
    },
    launchPay2() {
      var params = {
        userid: this.CYBoxUser.userID,
        token: this.CYBoxUser.loginToken,
        lecoinId: this.params,
        orderType: this.radio2,
        time: this.time
      }
      params.sign = ksort(params, "qsdhajasfas123sq")
      askPayWith(params).then(res => {
        if(res.state == '1') {
          this.buymenu = false

          try {
            window.CYEventCenter.CYPay(res.message.url);
          } catch (error) {}
          try {
            window.webkit.messageHandlers.CYPay.postMessage(res.message.url);
          } catch (error) {}

          // window.open(res.message.url)

          this.params = 500
        }
      })
    },
    selectPay(i) {
      this.currentSelect = i
      this.params = this.cards[i].num
    },
    isAgreement() {
      this.admin.agreement = true
      askAgreement().then((res) => {
        this.agreementList = res;
      });
    }
  },
};
</script>
<style scoped>
.the-game-in-enter-active,
.the-game-in-leave-active,
.the-game-out-enter-active,
.the-game-out-leave-active {
  will-change: transform;
  position: absolute;
  transition: all 500ms;
}

.the-game-in-enter {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
.the-game-in-leave-active {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
.the-game-out-enter {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
.the-game-out-leave-active {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
</style>
<style lang="stylus" scoped>
.container {
  height 100vh
}
@import '../components/common.styl';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 202px;
  height: 240px;
  background-color: #fff;
  border-radius 5px;
  overflow: hidden;
}

.page_pay {
  width: 190px;
  height: 48px;
  border-radius 24px;
  background:linear-gradient(90deg,#ff5555 50%,#fe0000 100%);
  color #fff
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto 0;
  font-size 18px
}

.page_scroll_view {
  padding-bottom: 5px;
  margin-bottom: 60px;
}

.page_game {
  background: $themeWhite;
  margin: 0 auto 4px;
  margin-top: calc(40px + constant(safe-area-inset-top));
  margin-top: calc(40px + env(safe-area-inset-top));
}

.page_top {
  margin: 0 auto;
  padding: 0 2% 0;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: $themeWhite;
  padding-top: constant(safe-area-inset-top)
  padding-top: env(safe-area-inset-top)
  height: calc(40px + constant(safe-area-inset-top));
  height: calc(40px + env(safe-area-inset-top));
}


.page_banner {
  width: 100%;
  text-align: center;
}

.page_banner_imageview {
  margin: 0 auto 10px;
  width: $viewWidth;
}

.page_banner_image {
  width: 100%;
  height: 100%;
  border-radius 5px
  background #f4f4f4
}

.page_back {
  display: flex;
  align-items: center;
}

.page_back_icon {
  height: 12px;
  width: 8px;
}

.page_back_name {
  font-size: 16px;
  font-weight: 600;
  padding-left: 6px;
}

.page_service {
  display: flex;
  align-items: center;
}

.page_service_cus {
  height: 28px;
  width: 28px;
}

.page_service_share {
  height: 20px;
  width: 20px;
  margin-left: 18px;
}

.page_start {
  width: 100%;
  height: 64px;
  background: $themeWhite;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.page_start_button {
  width: 294px;
  height: 38px;
  line-height: 38px;
  color: #ffffff;
  background: linear-gradient(90deg, $themeMin 35%, $themeMax 100%);
  text-align: center;
  border-radius: 19px;
  font-size: 18px;
  margin: 6px auto;
  // font-weight 600
  letter-spacing 1px
}

.page_tab {
  background: $themeBackground;
  padding-top: 1px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.page_tab_item {
  margin-top: 6px;
}

.page_gift {
  margin: 5px auto;
}
.page_null {
  background: #fff;
  text-align: center;
  height: 50px
  line-height: 50px;
  color: #666;
  width: 96%;
  margin 6px auto
  border-radius 5px
  font-size 14px
}

.game_content {
  width: 100%;
  height: 100%;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
}

.game_core {
  width: 100%;
  height: 100%;
}

.game_coreH {
  padding-bottom: 0;
  padding-left: calc(constant(safe-area-inset-left) - 10px);
  padding-left: calc(env(safe-area-inset-left) - 10px);
}

.game_coreS {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
.game_top_close {
  z-index: 1000;
  position: absolute;
  right: 20px;
  top: calc(10px + constant(safe-area-inset-top));
  top: calc(10px + env(safe-area-inset-top));
  width: 80px;
  display: flex
  align-items center
  justify-content: space-around;
  height 28px
  border-radius 18px
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255,255,255,0.3);
  padding 0 4px
}
// .game_new_close {
//   z-index: 1000;
//   position: absolute;
// }
.game_new_customer {
  height 100%
  width 100%
  position: absolute;
}
.ball {
  position: fixed;
  right: -.4em;
  top: 30%;
  width: 42px;
  height: 42px;
  z-index: 100;
  background-clip: padding-box;
  text-decoration: none;
}
.ball.ball_right .game_new_customer {
  right: 0;
  bottom: 0
}
.ball.ball_left .game_new_customer {
  left: 0;
  bottom: 0
}
.open_close {
  position: absolute;
  right: 13px;
  top: 0;
  // width 112px
  // height 41.5px 
  width: 108px;
  height: 37.5px;
  // background linear-gradient(90deg, $themeMin 35%, $themeMax 100%)
  background url("../assets/g.png") center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
}
.open_close_view {
  height 100%
  width: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.open_tip {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.open_tip_img {
  height 70%
}
.open_close_l {
  height 48%
  position: relative;
  left: 1px;
}
.game_line {
  width 1px
  height 14px
  background rgba(255,255,255,0.3)
}
.game_customer, .game_close {
  width: 24px;
  height: 24px;
}
.cash_view {
  margin 0 auto;
  // width 96%;
  width 330px
  position relative
  color #fff
}
.cash_receive {
  width 100%;
}
.cash_receive_gray {
  color #666 !important
}

.cash_num {
  position absolute
  top 8px
  left 16px
  font-size 32px
}
.cash_text {
  position absolute
  top 16px
  left 104px
  color #ff0103
  font-size 14px
}
.cash_text_gray {
  color #666
}
.cash_time {
  position absolute
  top 58px
  left 16px
  font-size 12px
}
.cash_name {
  position absolute
  top 72px
  left 16px
  font-size 12px
}
.cash_get {
  font-size 14px
  color #fff
  position absolute
  top -4px
  right 44px
  height 100%
  display flex
  align-items: center;
}

.van-overlay {
  z-index: 100 !important;
}
</style>

<style lang="stylus" scoped>
@import '../components/common.styl';
.my_payview
  width $viewWidth
  background #fff
  margin 0 auto 10px
  border-radius 2px
.my_v
  color $themeMax
  text-align: center;
  padding-top 30px
  padding-bottom 30px
.my_symbol
  font-size 20px
.my_pay
  font-size 36px
.my_pay_txt
  font-size 16px
  text-align center
  padding 10px
.my_pay_center
  padding 10px 0 10px
</style>

<style lang="stylus" scoped>
.card_tip
  width 96%
  margin: 0 auto 5px;
  padding 4px 8px;
  border-radius: 4px;
  font-size 14px
  background #fff
  color #f55
.card_tip_
  width 96%
  margin: 0 auto 0;
  padding 6px 8px 0;
  font-size 14px
  color #f55
.card_top
  background #fff
  border-radius 4px
  margin-bottom 10px
  width 96%
  margin 0 auto 5px;
  color #5d5a5a
  display flex
  flex-wrap: wrap;
  align-items: center;
  padding: 6px 0;
  justify-content: space-evenly;
.card_fuhao
  font-size 20px
.card_je
  font-size 34px
.card_center
  border-radius 4px
  background #fff
  // height 196px
  width 96%
  margin 0 auto
.card_fs
  font-size 18px
  text-align center
  padding-top 16px
  padding-bottom 28px
.card_select
  margin 0 auto
  width 80%

.card_card {
  font-size: 0;
  height: 130px;
  margin-bottom: 10px;
}

.card_title {
  font-size: 16px;
  margin-bottom: 6px;
}

.card_txt {
  font-size: 12px;
  text-align: left;
  width: 96%;
  margin: 0 auto;
  padding-bottom: 10px;
}

.card_text {
  margin-bottom: 4px;
  letter-spacing: 2px;
  line-height: 18px;
}

.card_i
  height 72px
  width 30%
  background #f6f6f6
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius 2px
  margin: 4px 0;
.card_count
  font-size 16px
  margin-bottom: 4px;
.card_des
  font-size 9px 
.page_pay
  width: 188px
  height: 46px;
  border-radius 23px;
  font-size 18px
  background: #20a0ff;
  background:linear-gradient(90deg,#ff5555 50%,#ff0000 100%);
  color #fff
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 28px auto 0;
</style>
<style scoped>
.cy_animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.cy_animated.cy_infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}
@media(print),
(prefers-reduced-motion) {
  .cy_animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    transition: none !important
  }
}

.cy_heartBeat {
  -webkit-animation-name: cy_heartBeat;
  animation-name: cy_heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out
}

@-webkit-keyframes cy_heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes cy_heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}
</style>